/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** Evergreen browsers require these. **/
import 'core-js/actual/reflect';
import 'core-js/actual/global-this';


/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
import 'zone.js';  // Included with Angular CLI.

/***************************************************************************************************
 * APPLICATION IMPORTS
 */

import 'reflect-metadata';
import 'polyfill-array-includes';
import { environment } from './environment';
import { isClientSide } from './environment-utils';

if (isClientSide() && environment.showDevTools) {
    if (localStorage['tyt:debug:periodicTasks']) {
        (function() {
            let base = globalThis['setInterval'];
            globalThis['setInterval'] = <any>function(fn: TimerHandler, time?: number, ...args: any[]) {
                let stack = new Error().stack.replace(/^Error/, '');
                if (Zone.current !== Zone.root && Zone.current.name !== 'asyncStackTagging for Angular') {
                    console.warn(`[Stability] Warning, setInterval() called within zone ${Zone.current.name}`);
                    console.warn(`[Stability] It is important to use PeriodicTasksService.schedule() instead of setInterval()!`);
                    console.warn(`[Stability] Originated from: ${stack}`);
                }
                return base(fn, time, ...args);
            }
        })();
        (function() {
            let base = globalThis['setTimeout'];
            globalThis['setTimeout'] = <any>function(fn: TimerHandler, time?: number, ...args: any[]) {

                let stack = new Error().stack.replace(/^Error/, '');

                if (Zone.current !== Zone.root && time > 1_000 && Zone.current.name !== 'asyncStackTagging for Angular') {
                    console.warn(`[Stability] Warning, long setTimeout() of ${time} ms was called within zone ${Zone.current.name}`);
                    console.warn(`[Stability] It is important to use PeriodicTasksService.scheduleOnce() instead of a long setTimeout()!`);
                    console.warn(`[Stability] Originated from: ${stack}`);
                }
                return base(fn, time, ...args);
            }
        })();
        (function() {
            let base = <any>globalThis['requestAnimationFrame'];
            globalThis['requestAnimationFrame'] = <any>function(...args) {

                let stack = new Error().stack.replace(/^Error/, '');

                if (Zone.current !== Zone.root) {
                    console.warn(`[Stability] Warning, requestAnimationFrame() was called within zone ${Zone.current.name}`);
                    console.warn(`[Stability] You should run requestAnimationFrame() outside of Angular and call into Angular only when needed for best perofrmance.`);
                    console.warn(`[Stability] Originated from: ${stack}`);
                }
                return base(...args);
            }
        })();
    }
}

/**
 * Date, currency, decimal and percent pipes.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */
// import 'intl';  // Run `npm install --save intl`.

if (typeof window !== 'undefined')
    (window as any).self = window;